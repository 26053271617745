import { Link as RemixLink, type LinkProps } from '@remix-run/react';
import { BlueButton, type IButtonProps } from '@hn-ui/buttons';
import { styled } from 'styled-components';

export type IButtonLinkProps = LinkProps & IButtonProps;

const ScBlueButton = styled(BlueButton)`
  box-sizing: border-box;
`;

const ButtonLink = ({
  children,
  ...rest
}: React.PropsWithChildren<IButtonLinkProps>) => {
  return (
    <ScBlueButton forwardedAs={RemixLink} {...rest}>
      {children}
    </ScBlueButton>
  );
};

export { ButtonLink };
